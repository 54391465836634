import { defaultStyles } from '@/utils/defaultStyles';
import SocialIcons from './SocialIcons';

function FooterBlock(props: {
  withSvg?: boolean;
  title: string;
  items: {
    title: string;
    route?: string;
    id?: string;
    isPhone?: boolean;
    isMail?: boolean;
  }[];
}) {
  return (
    <div className="flex flex-col gap-2">
      <div className="text-[#0A142F] text-[20px] font-[600] mb-4">
        {props.title}
      </div>
      {props.items.map((item, index) => (
        <div key={index} className="flex flex-row items-center gap-4">
          {props.withSvg && (
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="17.5" cy="17.5" r="17.5" fill="#F47E01" />
              <path
                d="M25.8187 23.9466C25.6804 24.2397 25.5013 24.5164 25.2653 24.7769C24.8664 25.2164 24.4269 25.5339 23.9304 25.7373C23.442 25.9408 22.9129 26.0466 22.3432 26.0466C21.5129 26.0466 20.6257 25.8513 19.6897 25.4525C18.7536 25.0536 17.8176 24.5164 16.8897 23.8408C15.9536 23.1571 15.0664 22.4001 14.2199 21.5618C13.3815 20.7153 12.6246 19.828 11.949 18.9001C11.2815 17.9722 10.7443 17.0443 10.3536 16.1246C9.96293 15.1966 9.76758 14.3094 9.76758 13.4629C9.76758 12.9094 9.86525 12.3804 10.0606 11.892C10.256 11.3955 10.5653 10.9397 10.9966 10.5327C11.5176 10.0199 12.0873 9.76758 12.6897 9.76758C12.9176 9.76758 13.1455 9.81642 13.349 9.91409C13.5606 10.0118 13.7478 10.1583 13.8943 10.3699L15.7827 13.0315C15.9292 13.235 16.035 13.4222 16.1083 13.6013C16.1815 13.7722 16.2222 13.9432 16.2222 14.0978C16.2222 14.2932 16.1653 14.4885 16.0513 14.6757C15.9455 14.8629 15.7908 15.0583 15.5955 15.2536L14.9769 15.8966C14.8873 15.9862 14.8466 16.092 14.8466 16.2222C14.8466 16.2873 14.8548 16.3443 14.8711 16.4094C14.8955 16.4746 14.9199 16.5234 14.9362 16.5722C15.0827 16.8408 15.335 17.1908 15.6932 17.6141C16.0594 18.0373 16.4501 18.4687 16.8734 18.9001C17.3129 19.3315 17.7362 19.7304 18.1676 20.0966C18.5908 20.4548 18.9408 20.699 19.2176 20.8455C19.2583 20.8618 19.3071 20.8862 19.3641 20.9106C19.4292 20.935 19.4943 20.9432 19.5676 20.9432C19.7059 20.9432 19.8118 20.8943 19.9013 20.8048L20.5199 20.1943C20.7234 19.9908 20.9187 19.8362 21.106 19.7385C21.2932 19.6246 21.4804 19.5676 21.6839 19.5676C21.8385 19.5676 22.0013 19.6001 22.1804 19.6734C22.3594 19.7466 22.5466 19.8525 22.7501 19.9908L25.4443 21.9036C25.6559 22.0501 25.8025 22.2211 25.892 22.4246C25.9734 22.628 26.0222 22.8315 26.0222 23.0594C26.0222 23.3525 25.9571 23.6536 25.8187 23.9466Z"
                stroke="white"
                strokeWidth="1.5"
              />
            </svg>
          )}
          <div
            className={`text-[16px] text-[#0A142F] cursor-pointer btn btn-ghost`}
            onClick={() => {
              if (item.isPhone) {
                window.open('https://wa.me/+48786633357', '_blank');
                return;
              }
              if (item.isMail) {
                window.open('mailto:kontakt@.com', '_blank');
                return;
              }
              if (item.route) {
                window.location.assign(item.route);
                return;
              }
              if (item.id) {
                if (
                  window.location.pathname == '/rodo' ||
                  window.location.pathname == '/privacy' ||
                  window.location.pathname == '/terms'
                ) {
                  window.location.assign('/');
                }
                document
                  .getElementById(item.id)!
                  .scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            {item.title}
          </div>
        </div>
      ))}
    </div>
  );
}

const footerItems = [
  {
    title: 'DUOINVEST',
    items: [
      {
        title: 'Oferta',
        id: 'offer',
      },
      {
        title: 'Dla kogo',
        id: 'for_who',
      },
      {
        title: 'Warunki umowy',
        id: 'terms',
      },
      {
        title: 'Dlaczego my?',
        id: 'why_us',
      },
      {
        title: 'Jak to działa?',
        id: 'how_it_works',
      },
    ],
  },
  {
    title: 'POMOC',
    items: [
      { title: 'Polityka prywatności', route: '/privacy' },
      { title: 'Regulamin', route: '/terms' },
      { title: 'RODO', route: '/rodo' },
    ],
  },
];

function Footer() {
  return (
    <>
      <footer
        className={`bg-white ${defaultStyles.padding} pb-6 flex flex-col gap-8`}
      >
        <div className="flex flex-row justify-between flex-wrap gap-8">
          <div className="flex flex-col gap-4">
            <img
              loading="lazy"
              src="/logo.png"
              alt="duoinvest logo"
              className="w-[254px] h-[76px]"
            />
            <div className="w-[70px] h-[1px] bg-[#D1D1D1]" />
            <div className="text-[16px] text-black">
              Nazwa firmy | Miejscowosc 00, 00-000 | Polska
            </div>
          </div>
          <FooterBlock {...footerItems[0]} />
          <FooterBlock {...footerItems[1]} />
        </div>
        <SocialIcons />
        <div className="flex flex-row justify-between items-center gap-4">
          <div
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="w-[80px] h-[80px] border border-solid border-black rounded-full flex flex-col justify-center items-center cursor-pointer"
          >
            <svg
              width="14"
              height="23"
              viewBox="0 0 14 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.00033 21.3335L7.00032 1.3335M7.00032 1.3335L1.66699 6.66683M7.00032 1.3335L12.3337 6.66683"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="font-bold text-[#9C9C9C] text-[14px]">
            {new Date().getFullYear()} Created by Askay
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
