import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikFormField } from './FormikFormField';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Imię i nazwisko są wymagane'),
  phone: Yup.string().required('Numer telefonu jest wymagany'),
  email: Yup.string().email().required('Email jest wymagany'),
  companyName: Yup.string().required('Nazwa firmy jest wymagana'),
  nip: Yup.string().required('Nip jest wymagany'),
  agreementsPolicy: Yup.boolean().oneOf(
    [true],
    'Zgody polityki prywatności są wymagane',
  ),
  agreementsTerms: Yup.boolean().oneOf(
    [true],
    'Zgody regulaminowe są wymagane',
  ),
});

export function ContactForm(props: { contactFormRed?: boolean }) {
  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: '',
        companyName: '',
        nip: '',
        agreementsTerms: false,
        agreementsPolicy: false,
        newsletter: false,
      }}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {({ values, errors, handleSubmit, isSubmitting }) => (
        <form
          onSubmit={handleSubmit}
          className={`flex flex-col gap-4 bg-white rounded-[8px] p-2 md:p-4  max-w-[579px]`}
        >
          <div className={`flex flex-col gap-4`}>
            <div className="flex flex-row gap-4">
              <div
                className={`max-w-[89px] min-w-[89px] h-[89px] rounded-full flex flex-col justify-center items-center border border-solid ${props.contactFormRed ? 'border-[#F81E1E] bg-[#F81E1E28]' : 'border-[#008837] bg-[#00883728]'}`}
              >
                <svg
                  width="39"
                  height="39"
                  viewBox="0 0 39 39"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28.837 23.8563C28.837 24.2623 28.8214 24.6526 28.7745 25.0274C28.4154 29.2432 25.9328 31.3354 21.3578 31.3354H20.7333C20.3429 31.3354 19.9682 31.5228 19.734 31.8351L17.8603 34.3333C17.0328 35.4419 15.6899 35.4419 14.8624 34.3333L12.9887 31.8351C12.7857 31.5696 12.3329 31.3354 11.9894 31.3354H11.3649C6.384 31.3354 3.88574 30.1019 3.88574 23.8563V17.6107C3.88574 13.0358 5.99365 10.5532 10.1938 10.194C10.5686 10.1472 10.9589 10.1316 11.3649 10.1316H21.3578C26.3387 10.1316 28.837 12.6298 28.837 17.6107V23.8563Z"
                    stroke={props.contactFormRed ? '#F81E1E' : '#008837'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M35.0821 17.6107C35.0821 22.2012 32.9742 24.6683 28.7741 25.0274C28.8209 24.6526 28.8365 24.2623 28.8365 23.8563V17.6107C28.8365 12.6298 26.3383 10.1316 21.3574 10.1316H11.3644C10.9584 10.1316 10.5681 10.1472 10.1934 10.194C10.5525 5.99387 13.0351 3.88597 17.61 3.88597H27.603C32.5839 3.88597 35.0821 6.38422 35.0821 11.3651V17.6107Z"
                    stroke={props.contactFormRed ? '#F81E1E' : '#008837'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21.8342 21.4518H21.8483"
                    stroke={props.contactFormRed ? '#F81E1E' : '#008837'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.3694 21.4518H16.3834"
                    stroke={props.contactFormRed ? '#F81E1E' : '#008837'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.9045 21.4518H10.9186"
                    stroke={props.contactFormRed ? '#F81E1E' : '#008837'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="text-[35px] text-black">
                Wypełnij{' '}
                <span
                  className={`font-bold ${props.contactFormRed ? 'text-[#F81E1E]' : 'text-[#008837]'}`}
                >
                  formularz kontaktowy
                </span>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4">
              <FormikFormField
                label="Imię i nazwisko"
                placeholder="Wpisz imię i nazwisko"
                name="name"
                value={values.name}
              />
              <FormikFormField
                label="E-Mail"
                placeholder="Wpisz adres e-mail"
                name="email"
                value={values.email}
              />
              <FormikFormField
                label="Numer telefonu"
                placeholder="Wpisz numer telefonu"
                name="phone"
                value={values.phone}
              />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <FormikFormField
                  label="Nazwa firmy"
                  placeholder="Wpisz nazwę firmy"
                  name="companyName"
                  value={values.companyName}
                />
                <FormikFormField
                  label="NIP"
                  placeholder="Wpisz numer NIP"
                  name="nip"
                  value={values.nip}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4">
              <label className="text-[12px]">
                <Field type="checkbox" name="agreementsTerms" />
                *Akceptuję{' '}
                <a target="_blank" href="/terms">
                  regulamin
                </a>
              </label>
              <label className="text-[12px]">
                <Field type="checkbox" name="agreementsPolicy" />
                *Wyrażam zgodę na przetwarzanie moich danych osobowych przez
                (...), w celu udzielenia odpowiedzi na zapytanie przesłane
                formularzem. Więcej informacji na temat przetwarzania danych
                osobowych można znaleźć w{' '}
                <a target="_blank" href="/privacy">
                  polityce prywatności
                </a>
                .
              </label>
              <label className="text-[12px]">
                <Field type="checkbox" name="newsletter" />
                Chcę otrzymywać newsletter
              </label>
            </div>
            {Object.keys(errors).length > 0 && (
              <div className="text-red-500 text-xs">
                <ul>
                  {Object.values(errors).map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
            <button
              type="submit"
              className="rounded-[12px] font-bold text-white bg-black border-none outline-none h-[42px] max-w-[200px] cursor-pointer"
            >
              Wyślij
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}
