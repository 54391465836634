import { Field } from 'formik';

export function FormikFormField(props: {
  placeholder: string;
  value?: string;
  name?: string;
  class?: string;
  type?: string;
  label?: string;
}) {
  return (
    <div className="flex flex-col gap-2">
      {props.label && (
        <div className="text-[16px] text-[#9C9C9C]">{props.label}</div>
      )}
      <Field
        name={props.name}
        type={props.type ?? 'text'}
        placeholder={props.placeholder}
        value={props.value}
        className={`py-4 px-2 bg-transparent rounded-[8px] border border-solid border-[#E1E1E1] outline-none ${props.class}`}
      />
    </div>
  );
}
