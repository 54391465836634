function SocialItem(props: { svg: JSX.Element; title: string }) {
  return (
    <div className="w-[300px] py-2 flex flex-row justify-between items-center gap-4 border border-solid border-t-0 border-l-0 border-r-0 border-[#DDDDDD]">
      <div className="text-[16px] font-[300] text-black">{props.title}</div>
      {props.svg}
    </div>
  );
}

export default function SocialIcons() {
  return (
    <div className="flex flex-col md:flex-row gap-4 justify-between items-center">
      <SocialItem
        title="Twitter"
        svg={
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_109_496)">
              <path
                d="M17.5 0C7.83535 0 0 7.83535 0 17.5C0 27.1646 7.83535 35 17.5 35C27.1646 35 35 27.1646 35 17.5C35 7.83535 27.1646 0 17.5 0Z"
                fill="black"
              />
              <path
                d="M19.4149 15.9163L26.9064 7.20801H25.1312L18.6262 14.7693L13.4308 7.20801H7.43848L15.295 18.642L7.43848 27.774H9.21383L16.0832 19.789L21.5699 27.774H27.5623L19.4144 15.9163H19.4149ZM9.85351 8.54448H12.5803L25.132 26.4983H22.4051L9.85351 8.54448Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_109_496">
                <rect width="35" height="35" fill="white" />
              </clipPath>
            </defs>
          </svg>
        }
      />
      <SocialItem
        title="Facebook"
        svg={
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_109_503)">
              <path
                d="M35 17.6064C35 26.3404 28.5921 33.581 20.2271 34.895V22.6989H24.2944L25.0688 17.6531H20.2271V14.3792C20.2271 12.9981 20.9037 11.6535 23.0708 11.6535H25.2715V7.35728C25.2715 7.35728 23.2735 7.01603 21.3646 7.01603C17.3775 7.01603 14.7729 9.43249 14.7729 13.806V17.6517H10.341V22.6975H14.7729V34.8935C6.40938 33.5781 0 26.3389 0 17.6064C0 7.94207 7.83563 0.106445 17.5 0.106445C27.1644 0.106445 35 7.94061 35 17.6064Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_109_503">
                <rect width="35" height="35" fill="white" />
              </clipPath>
            </defs>
          </svg>
        }
      />
      <SocialItem
        title="LinkedIn"
        svg={
          <svg
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_109_509)">
              <path
                d="M17.5 0C7.83649 0 0 7.83649 0 17.5C0 27.1635 7.83649 35 17.5 35C27.1635 35 35 27.1635 35 17.5C35 7.83649 27.1635 0 17.5 0ZM12.4147 26.4551H8.15266V13.6326H12.4147V26.4551ZM10.2838 11.8817H10.256C8.82584 11.8817 7.90085 10.8972 7.90085 9.66671C7.90085 8.40847 8.85414 7.45117 10.3121 7.45117C11.7701 7.45117 12.6673 8.40847 12.6951 9.66671C12.6951 10.8972 11.7701 11.8817 10.2838 11.8817ZM27.7827 26.4551H23.5212V19.5954C23.5212 17.8714 22.9041 16.6957 21.362 16.6957C20.1847 16.6957 19.4835 17.4888 19.1753 18.2544C19.0627 18.5283 19.0351 18.9112 19.0351 19.2944V26.4551H14.7734C14.7734 26.4551 14.8292 14.8356 14.7734 13.6326H19.0351V15.4482C19.6015 14.5744 20.6149 13.3317 22.8761 13.3317C25.6802 13.3317 27.7827 15.1643 27.7827 19.1027V26.4551Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_109_509">
                <rect width="35" height="35" fill="white" />
              </clipPath>
            </defs>
          </svg>
        }
      />
    </div>
  );
}
